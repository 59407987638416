import { InjectionToken } from '@angular/core';
import { RootTheme } from '@app/shared/models/enums/theme.enum';
import { Request, Response } from 'express';

export const REQUEST = new InjectionToken<Request>('REQUEST');

export const RESPONSE = new InjectionToken<Response>('RESPONSE');

export const ACCESS_TOKEN = new InjectionToken<string>('ACCESS_TOKEN');

export const REFRESH_TOKEN = new InjectionToken<string>('REFRESH_TOKEN');

export const THEME_TOKEN = new InjectionToken<RootTheme>('THEME_TOKEN');
